@import '@radix-ui/colors/sage.css';
@import '@radix-ui/colors/sage-dark.css';

:root {
  --foreground: var(--sage-12);
  --background: var(--sage-1);
}

html,
body,
.light,
.dark,
.light body,
.dark body {
  color: var(--foreground);
  background: var(--background);
}

a {
  @apply outline-grass-9;
}
