@import '@radix-ui/themes/styles.css';

.radix-themes {
  --cursor-button: pointer;
  --cursor-checkbox: pointer;

  /**
  * fonts
  * https://www.radix-ui.com/themes/docs/theme/typography#with-nextfont
  * https://github.com/radix-ui/themes/issues/117
  */
  --default-font-family: var(--font-inter) !important;

  *:not(.dark) {
    --color-overlay: var(--white-a11);
  }
}
